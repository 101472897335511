
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  中古車選びや人気モデルの特徴・種類について解説
                </p>
              </div>
              <div class="button-area uk-visible@s">
                <div class="btn-copy">クリック</div>
                <a href="https://www.worldingsysdom.com/stock_info/list/car" class="catch_btn btn-c btn--yellow btn--cubic">中古車のお得情報はこちら</a>
              </div>
            </div>
          </div>

          
          <div class=" uk-hidden@s">
              <div class="btn-copy">クリック</div>
              <a href="https://www.worldingsysdom.com/stock_info/list/car" class="catch_btn btn-c btn--yellow btn--cubic">中古車のお得情報はこちら</a>
          </div>

          <div class="sub-card"  >
            <div class="section-title">
              <span >コラム一覧</span>
            </div>
            <div class="fv">
              <p><span>プレミアムカーの中古車のご購入を検討の際は、国産車・外車の人気メーカーをはじめ、希少なスカイラインなどレアな車種やモデルの中古車を取り揃える「car shop MIX」までお問い合わせください。</span></p>
              <p><span>こちらでは、GT-Rの特徴やメルセデスベンツのAMGモデル、中古車（MT・AT）の販売店を選ぶ際のポイントをご紹介いたします。</span></p>
            </div>
          </div>
          <column-list :title="'中古車・希少車選びや人気モデルのGT-R・AMGに関するコラム'" :linkList="linkListGtrAmg"/>
          <column-list :title="'中古車で人気のモデルやメーカー・MT車に関するコラム'" :linkList="linkListMakerMt"/>
          <column-footer :title="'人気のスカイラインGT-RやメルセデスAMGなどの中古車をお探しならcar shop MIXへ'"/>
          <div class="sub-card"  >
            <div class="section-main">
              <section>
                <div class="seo-text">
                  <h2 class="u-mb20">プレミアムカーの中古車をお探しでしたらcar shop MIXへ！</h2>
                  <p class="text">プレミアムカーの中古車をお探しでしたら、car shop MIXをご利用ください。car shop MIXは国産車・外車を問わず幅広いメーカーのプレミアムカーを取り揃えています。</p>
                  <p class="text">一般的には出回らないような車種や希少価値が高いモデルを集めるノウハウがあるため、低走行車や25年規制が解除されるような車もご用意可能です。在庫一覧にお目当ての車種がない場合、代行してオークションでお探しいたします。</p>
                  <p class="text">「高値だけど有名なメーカーのMT車が欲しい」「日産やホンダなど国内メーカーの中古車が欲しい」という方は、お気軽にご相談ください。</p>
                  <div class="c-btn">
                    <a href="https://www.worldingsysdom.com/stock_info/list/car" class="c-btn-anchor" target="_blank" rel="noopener noreferrer nofollow">在庫一覧</a>
                    <router-link to="/jigyo" class="c-btn-anchor">事業内容</router-link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./column/Footer.vue";
import ColumnList from "./column/Columlist.vue";

export default {
  components: {
    Breadcrumb,
    ColumnFooter,
    ColumnList
  },
  data: () => ({
    linkListGtrAmg:[],
    linkListMakerMt:[],
  }),
  created() {
    this.linkListGtrAmg = this.$_menu(this.commonSetting.columMenuListGtrAmg);
    this.linkListMakerMt = this.$_menu(this.commonSetting.columMenuListMakerMt);
  },
}
</script>



<style scoped lang="scss">
.fv p {line-height: 2.3;margin: 0;padding-bottom: 1em;}
.fv p span {border-bottom: 2px dotted #929292;padding-bottom: 3px;}
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv00.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
}

.seo-text {
  border: 2px solid #929292;border-radius: 15px;
  padding: 1.5em 2em;
  margin: 50px 0;
  h2 {
    border-bottom: 2px solid #929292;color: #fff;
    font-size: 24px;padding: 10px 10px 15px;
    &::first-letter {
      color: #c49a00;
      font-weight: bold;
    }
  }
}
.seo-text .text {padding-bottom: 1em;margin: 0;}

</style>
